import '../src/polyfills.js'
import { createApp, reactive } from 'vue'
import NrDatepicker from '../src/vue_shared/components/datepicker.vue'

function initDatepicker(selector: string) {
  const datepickerEls = document.querySelectorAll(selector)

  datepickerEls.forEach((el: HTMLInputElement) => {
    // A vue instance for this element already exist, do nothing.
    // @ts-ignore
    if ($(el).data('vue')) return

    const mountEl = document.createElement('div')
    el.parentNode.insertBefore(mountEl, el)
    // NOTE: Setting the hidden attribute here so that screenreaders know it is
    // hidden and not to be tabbed into.
    el.hidden = true
    el.style.display = 'none'

    // Does this datepicker have a sibling?
    // @ts-ignore
    const $siblingEl = $(el.getAttribute('data-sibling'))
    // @ts-ignore
    const $autoSubmitForm = $(el.getAttribute('data-auto-submit-form'))

    // Data that can be accessed by code outside of the Vue app.
    const publicData = reactive({
      minDate: el.getAttribute('data-min-date'),
      maxDate: el.getAttribute('data-max-date'),
      selected: el.value,
      placeholder: el.getAttribute('placeholder'),
      disabled: el.getAttribute('disabled')
    })
    // @ts-ignore
    $(el).data('vue', publicData)

    // eslint-disable-next-line no-new
    const vueDatepicker = createApp({
      components: { NrDatepicker },
      data() {
        return publicData
      },
      template: `<NrDatepicker
        :minDate="minDate"
        :maxDate="maxDate"
        :placeholder="placeholder"
        :disabled="disabled"
        data-id="${el.id}"
        v-model="selected"
        @input="updateDate"
      >
          <template v-slot:label>${el.getAttribute('aria-label')}</template>
      </NrDatepicker>`,
      methods: {
        updateDate(date: string) {
          el.value = date

          if ($autoSubmitForm.length) {
            $autoSubmitForm.submit()
          }

          // Check if this datepicker has a sibling
          if (!$siblingEl.length) return
          // Check if the sibling datepicker has a vue instance
          const siblingVue = $siblingEl.data('vue')
          if (!siblingVue) return

          if (/start/i.test(el.id)) {
            // If this datepicker is the start datepicker
            // Update the end datepicker's minDate
            siblingVue.minDate = date
          } else if (/end/i.test(el.id)) {
            // If this datepicker is the end datepicker
            // Update the start datepicker's maxDate
            siblingVue.maxDate = date
          }
        }
      }
    })

    vueDatepicker.mount(mountEl)
  })
}

declare global {
  interface Window {
    initDatepicker: typeof initDatepicker
  }
}
window.initDatepicker = initDatepicker
document.addEventListener('DOMContentLoaded', () => {
  window.initDatepicker('.dateSelect')
})
