<script>
import NrDatepicker from '@nextrequestco/components/src/components/NrDatePicker/nr-datepicker.vue'
import NrIcon from '@nextrequestco/components/src/components/NrIcon/NrIcon.vue'
export default {
  components: { NrDatepicker, NrIcon },
  mounted() {
    this.$el.addEventListener('click', this.preventButtonPropagation)
  },

  beforeDestroy() {
    this.$el.removeEventListener('click', this.preventButtonPropagation)
  },

  methods: {
    // Prevent smart listing to auto submit forms when internal datepicker
    // buttons are clicked.
    preventButtonPropagation(e) {
      e.preventDefault()
      e.stopPropagation()
      return false
    }
  }
}
</script>

<template>
  <NrDatepicker :hideLabel="true" v-bind="$attrs">
    <template v-slot:label>
      <slot name="label"></slot>
    </template>
    <template v-slot:trigger><NrIcon name="calendar-alt" /> </template>
  </NrDatepicker>
</template>

<style lang="scss">
.nr-datepicker {
  .field {
    &.is-grouped {
      display: flex;
      justify-content: space-between;

      .control {
        flex: 0;
      }

      .is-expanded {
        flex: 1;
        margin-right: 0.5rem;
      }
    }

    input.input {
      font-size: 1rem;
      height: unset;
      line-height: 1.5;
      padding: 0.4375em 0.5em;
      border-radius: 0.25em;
    }
  }
}

.nr-datepicker-calendar {
  width: 20rem;
  position: absolute;
  z-index: 1;

  tr {
    &:nth-of-type(even) {
      background-color: unset;
    }
  }
}

.nr-datepicker-calendar-dates {
  .nr-datepicker-day--focus {
    &.is-white {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  .nr-button {
    margin: 0;
    padding: 0;

    &:focus {
      &:not(:active) {
        box-shadow: none;
        background-color: rgba(0, 0, 0, 0.2);
      }
    }

    &.is-primary {
      &:focus {
        background-color: #0f1c70;
      }
    }
  }
}
</style>
